<template>
  <v-dialog
    v-model="displayNotificationDetailsDialog"
    max-width="70vw"
  >
  <!-- persistent -->
    <v-card class="pa-6">
      <v-row>
        <v-col cols="12" v-if="Object.entries(this.notification).length > 0">
          <v-card-title class="px-0 py-0">
            <span
              class="font-weight-bold"
              style="overflow-wrap: anywhere; word-wrap: break-word; word-break: normal;hyphens: auto;"
            >
              {{ notification.data.title || notification.data.subject }}
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-0 py-0">
            <p class="text-right text-caption">{{ notification.created_at | formatDateTime }}</p>
            <p class="body-1">
              {{ notification.data.body }}
            </p>
            <div v-html="notification.data.data" style="background-color: white;">
              <!-- {{ notification.data.body }} -->
            </div>
            <template v-for="(item, i) in notification.data.lines">
                <p class="body-1" v-bind:key="i">{{ item }}</p>
            </template>
            <div
              v-if="notification.data.url"
              v-html="actionButton"
            ></div>
            <p v-if="notification.data.url" class="body-1"></p>
            <p class="body-1">{{ notification.data.endnote }}</p>
            <p class="body-1">{{ notification.data.thankyou }}</p>
          </v-card-text>
        </v-col>
        <v-col v-else></v-col>

      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col
          class="d-flex flex-wrap justify-content-end align-items-center"
          cols="12"
        >
          <v-btn v-if="showBackButton !== false" @click="closeDialog()">
            <span class="font-weight-bold">{{ backButton }}</span>
          </v-btn>
          <v-btn
            class="ml-4"
            color="primary"
            :loading="isLoading"
            v-if="showProceedButton !== false"
            @click="onProceed()"
          >
            <span class="font-weight-bold">{{ proceedButton }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "NotificationDetailDialog",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showProceedButton: {
      type: Boolean,
      default: false,
    },
    proceedButton: {
      type: String,
      default: "",
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    backButton: {
      type: String,
      default: "",
    },
    notification: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    displayNotificationDetailsDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    actionButton() {
      // return '<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"><span class="v-btn__content"><span class="font-weight-bold">' + this.notification.data.action + '</span></span></button>';
      return "<a href='" + this.notification.data.url + "' class='v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default' target='_blank'>" + this.notification.data.action + "</a>"
    },
  },
  methods: {
    closeDialog() {
      this.displayNotificationDetailsDialog = false;
    },
  },
};
</script>
<style>
</style>