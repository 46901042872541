var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "70vw" },
      model: {
        value: _vm.displayNotificationDetailsDialog,
        callback: function ($$v) {
          _vm.displayNotificationDetailsDialog = $$v
        },
        expression: "displayNotificationDetailsDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-6" },
        [
          _c(
            "v-row",
            [
              Object.entries(this.notification).length > 0
                ? _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-card-title", { staticClass: "px-0 py-0" }, [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold",
                            staticStyle: {
                              "overflow-wrap": "anywhere",
                              "word-wrap": "break-word",
                              "word-break": "normal",
                              hyphens: "auto",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.notification.data.title ||
                                    _vm.notification.data.subject
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        { staticClass: "px-0 py-0" },
                        [
                          _c("p", { staticClass: "text-right text-caption" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDateTime")(
                                  _vm.notification.created_at
                                )
                              )
                            ),
                          ]),
                          _c("p", { staticClass: "body-1" }, [
                            _vm._v(
                              " " + _vm._s(_vm.notification.data.body) + " "
                            ),
                          ]),
                          _c("div", {
                            staticStyle: { "background-color": "white" },
                            domProps: {
                              innerHTML: _vm._s(_vm.notification.data.data),
                            },
                          }),
                          _vm._l(
                            _vm.notification.data.lines,
                            function (item, i) {
                              return [
                                _c("p", { key: i, staticClass: "body-1" }, [
                                  _vm._v(_vm._s(item)),
                                ]),
                              ]
                            }
                          ),
                          _vm.notification.data.url
                            ? _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(_vm.actionButton),
                                },
                              })
                            : _vm._e(),
                          _vm.notification.data.url
                            ? _c("p", { staticClass: "body-1" })
                            : _vm._e(),
                          _c("p", { staticClass: "body-1" }, [
                            _vm._v(_vm._s(_vm.notification.data.endnote)),
                          ]),
                          _c("p", { staticClass: "body-1" }, [
                            _vm._v(_vm._s(_vm.notification.data.thankyou)),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c("v-col"),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex flex-wrap justify-content-end align-items-center",
                  attrs: { cols: "12" },
                },
                [
                  _vm.showBackButton !== false
                    ? _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.closeDialog()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.backButton)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.showProceedButton !== false
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { color: "primary", loading: _vm.isLoading },
                          on: {
                            click: function ($event) {
                              return _vm.onProceed()
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(_vm._s(_vm.proceedButton)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }